<!-- ----------------------------------header---section----------------------------- -->
<nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
  <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
    <a class="navbar-brand brand-logo me-5" href="index.html"><img src="../../assets/images/cocloud-logo.jpg"
        class="me-2 logo" alt="logo" /></a>
    <a class="navbar-brand brand-logo-mini" href="index.html"><img src="../../assets/images/cocloud-logo.jpg" alt="logo"
        class="logo" /></a>
  </div>
  <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
    <button class="navbar-toggler navbar-toggler align-self-center" type="button" aria-label="Name"
      (click)="onToggle();">
      <span class="icon-menu"></span>
    </button>

    <ul class="navbar-nav navbar-nav-right">
      <li class="text-center mt-3 me-4">
        <div class="wallet">
          <p class="mb-0 text-center ">Wallet: &nbsp;<b>{{balanceAmount |
              currency:'INR'}}</b></p>
        </div>
      </li>
      <li class="text-center mt-3 me-2">
        <p class="name mb-0 "><i class="bi bi-person-circle me-2 fs-5"></i><b>{{userName | titlecase}}</b></p>
      </li>
      <li class="nav-item nav-profile dropdown">
        <span class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" id="profileDropdown">
          <i class="bi bi-three-dots-vertical "></i>
        </span>
        <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
          <p class="dropdown-item" (click)="openModal()">
            <i class="ti-power-off text-primary"></i>
            Logout
          </p>
        </div>
      </li>

    </ul>
    <button class="hide-menu d-lg-none align-self-center" type="button" (click)="hideBtn()">
      <span class="icon-menu"></span>
    </button>
  </div>
</nav>
<!-- -------------------sidebar---------------- -->
<div class="container-fluid page-body-wrapper custome-sidebar">
  <nav class="sidebar min-menu" id="sidebar "
    [ngClass]="showNav ? (showNav ? 'show-nav' : 'sidebar-width'): (isShow ? 'show-nav': 'hide-nav')">
    <ul class="nav  ">
      <li class="nav-item" *ngIf="isPartnerSalesman" (click)="sideNavToggle()">
        <a class="nav-link1" routerLink="/dashboard" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact:
      true}">
          <i class="bi bi-grid-fill menu-icon"></i>
          <span class="menu-title">Dashboard</span>
        </a>
      </li>

      <li class="nav-item" *ngIf="!userType" (click)="sideNavToggle()">
        <a class="nav-link1" data-bs-toggle="collapse" [routerLink]="['/account']" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{exact:
    false}" aria-expanded="false" aria-controls="e-commerce">
          <i class="bi bi-person-fill menu-icon"></i>
          <span class="menu-title">Customer</span>
        </a>
      </li>

      <li class="nav-item" (click)="sideNavToggle()">
        <a class="nav-link1" data-bs-toggle="collapse" [routerLink]="['/cloud-user']"
          [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact:
    false}" aria-expanded="false" aria-controls="e-commerce">
          <i class="bi bi-people-fill menu-icon"></i>
          <span class="menu-title">Cloud User</span>
        </a>
      </li>

      <li class="nav-item" *ngIf="!userType" (click)="sideNavToggle()">
        <a class="nav-link1" data-bs-toggle="collapse" [routerLink]="['/sales-man']"
          [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact:
    false}" aria-expanded="false" aria-controls="e-commerce">
          <i class="bi bi-person-bounding-box menu-icon"></i>
          <span class="menu-title">User & Roles</span>
        </a>
      </li>

      <!-- <li class="nav-item">
        <a class="nav-link  " data-bs-toggle="collapse" fragment="advanced" href="#advanced" aria-expanded="false"
          aria-controls="advanced">
          <i class="bi bi-sliders2-vertical menu-icon"></i>
          <span class="menu-title">Subscription</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="collapse" id="advanced">
          <ul class="nav flex-column sub-menu ">
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/subscriptions-list']" [routerLinkActive]="['active-link']"
                [routerLinkActiveOptions]="{exact:
            true}">
                <span class="menu-title">All Subscriptions</span>
              </a>
            </li>
            <li class="nav-item">
          <a class="nav-link" [routerLink]="['/activity']" [routerLinkActive]="['active-link']"
            [routerLinkActiveOptions]="{exact:
            true}">

            <span class="menu-title">My Activity</span>
          </a>
        </li>
          </ul>
        </div>
      </li> -->

      <li class="nav-item" (click)="sideNavToggle()">
        <a class="nav-link1" [routerLink]="['/subscriptions-list']" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{exact:
      false}">
          <i class="bi bi-award menu-icon"></i>
          <span class="menu-title">Subscriptions</span>
        </a>
      </li>

      <li class="nav-item" *ngIf="!isSubUser" (click)="sideNavToggle()">
        <a class="nav-link1 side-links d-block" [routerLink]="['/branding']" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{exact: true}">
          <p class="menu-title mb-0">
            <i class="bi bi-tags-fill menu-icon"></i> Branding
          </p>
        </a>
      </li>

      <li class="nav-item" *ngIf="!isSubUser" (click)="sideNavToggle()">
        <a class="nav-link1 side-links d-block" [routerLink]="['/google-drive']" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{exact: true}">
          <p class="menu-title mb-0">
            <i class="mdi mdi-google-drive menu-icon"></i> Google Drive
          </p>
        </a>
      </li>

      <li class="nav-item" *ngIf="!isSubUser" (click)="sideNavToggle()">
        <a class="nav-link1" [routerLink]="['/billing']" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact:
      true}">
          <i class="bi bi-receipt menu-icon"></i>
          <span class="menu-title">Billing</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="!userType" (click)="sideNavToggle()">
        <a class="nav-link1" [routerLink]="['/audit-log']" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{exact:
      true}">
          <i class="bi bi-info-square-fill menu-icon"></i>
          <span class="menu-title">Audit Log</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="partnerId=='645a4eca8c6428e9ac655957' && role!='salesPerson'"
        (click)="sideNavToggle()">
        <a class="nav-link1" [routerLink]="['/license-details']" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{exact:
      true}">
          <i class="bi bi-briefcase-fill menu-icon"></i>
          <span class="menu-title">License Details</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="partnerId=='645a4eca8c6428e9ac655957' && role!='salesPerson'"
        (click)="sideNavToggle()">
        <a class="nav-link1 w-100 d-flex flex-row justify-content-between items-center"
          href="https://docs.partners.cocloud.in/introduction/introduction" target="_blank">
          <div class="d-flex flex-row items-center">
            <i class="bi bi-lightbulb-fill menu-icon"></i>
            <span class="menu-title">Knowledge Base</span>
          </div>
          <i class="bi bi-arrow-up-right float-end me-3" style="font-size: 0.8rem;"></i>
        </a>
      </li>
      <!-- <li class="nav-item" *ngIf="!isSubUser" (click)="sideNavToggle()">
        <a class="nav-link1" [routerLink]="['/order-management']" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact:
      true}" >
          <i class="bi bi-card-text menu-icon"></i>
          <span class="menu-title">Order Management</span>
        </a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" data-bs-toggle="collapse" fragment="ui-advanced" href="#ui-advanced"
          (click)="toggleMenu('ui-advanced')" [attr.aria-expanded]="expandedMenu === 'ui-advanced' ? 'true' : 'false'"
          aria-controls="ui-advanced">
          <i class="bi bi-list-ol menu-icon" [ngStyle]="{'color': expandedMenu === 'ui-advanced' ? 'white' : ''}"></i>
          <span class="menu-title" [ngStyle]="{'color': expandedMenu === 'ui-advanced' ? 'white' : ''}">Service
            Request</span>
          <i class="menu-arrow" [ngStyle]="{'color': expandedMenu === 'ui-advanced' ? 'white' : '#130F26'}"></i>
        </a>
        <div class="collapse" [ngClass]="{'show': expandedMenu === 'ui-advanced'}" id="ui-advanced">
          <ul class="nav flex-column sub-menu ">
            <li class="nav-item" (click)="sideNavToggle()">
              <a class="nav-link" [routerLink]="['ticket-to-cocloud']" [routerLinkActive]="['active-link']"
                [routerLinkActiveOptions]="{exact:
                true}">

                <span class="menu-titled">Tickets to CoCloud</span>
              </a>
            </li>
            <li class="nav-item" (click)="sideNavToggle()">
              <a class="nav-link" [routerLink]="['customer-ticket']" [routerLinkActive]="['active-link']"
                [routerLinkActiveOptions]="{exact:
                true}">

                <span class="menu-titled">Tickets from Customer</span>
              </a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" [routerLink]="['raise-service-request']" [routerLinkActive]="['active-link']"
                [routerLinkActiveOptions]="{exact:
                true}">

                <span class="menu-title">Raise Request</span>
              </a>
            </li> -->

          </ul>
        </div>
      </li>

      <!-- <li class="nav-item" *ngIf="!userType">
        <a class="nav-link1" [routerLink]="['/reports']" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact:
      true}" (click)="sideNavToggle()">
          <i class="bi bi-graph-up-arrow menu-icon"></i>
          <span class="menu-title">Reports</span>
        </a>
      </li> -->

      <!-- <li class="nav-item" *ngIf="!userType">
        <a class="nav-link1" [routerLink]="['/setting']" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact:
      true}" (click)="sideNavToggle()">
          <i class="ti-settings  menu-icon"></i>
          <span class="menu-title">Settings</span>
        </a>
      </li> -->

      <!-- <li class="nav-item">
        <a class="nav-link" [routerLink]="['/reports']" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{exact:
          true}">
          <i class="bi bi-graph-up-arrow menu-icon"></i>
          <span class="menu-title">Reports</span>
        </a>
      </li> -->

      <li class="nav-item" *ngIf="partnerId=='645a4eca8c6428e9ac655957'">
        <a class="nav-link log-link" data-bs-toggle="collapse" href="#reports" aria-controls="reports"
          (click)="toggleMenu('reports')" [attr.aria-expanded]="expandedMenu === 'reports' ? 'true' : 'false'">
          <i class="bi bi-graph-up-arrow menu-icon"
            [ngStyle]="{'color': expandedMenu === 'reports' ? 'white' : ''}"></i>
          <span class="menu-title" [ngStyle]="{'color': expandedMenu === 'reports' ? 'white' : ''}">Reports</span>
          <i class="menu-arrow" [ngStyle]="{'color': expandedMenu === 'reports' ? 'white' : '#130F26'}"></i>
        </a>
        <div class="collapse" [ngClass]="{'show': expandedMenu === 'reports'}" id="reports">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['mrr-report']" [routerLinkActive]="['active-link']"
                [routerLinkActiveOptions]="{exact: true}" (click)="sideNavToggle()">

                <span class="menu-titled">MRR Report</span>
              </a>
            </li>

            <li class="nav-item" *ngIf="!userType ||(userType && isSalesman)">
              <a class="nav-link" [routerLink]="['salesperson-report']" [routerLinkActive]="['active-link']"
                [routerLinkActiveOptions]="{exact: true}" (click)="sideNavToggle()">

                <span class="menu-titled">Salesperson report</span>
              </a>
            </li>
            <li class="nav-item" *ngIf="!userType ||(userType && !isSalesman)">
              <a class="nav-link" [routerLink]="['relationship-manager-report']" [routerLinkActive]="['active-link']"
                [routerLinkActiveOptions]="{exact: true}" (click)="sideNavToggle()">

                <span class="menu-titled">RM Report</span>
              </a>
            </li>
          </ul>
        </div>
      </li>

      <!-- <li class="nav-item" *ngIf="!userType">
        <a class="nav-link1" routerLink="topUp" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact:
      true}" (click)="sideNavToggle()">
          <i class="icon-paper menu-icon"></i>
          <span class="menu-title">Top Up Account</span>
        </a>
      </li> -->
      <!-- <li class="nav-item" *ngIf="!userType">
        <a class="nav-link" routerLink="roles-permissions" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{exact:
      true}" (click)="sideNavToggle()">
          <i class="bi bi-person-fill-gear menu-icon"></i>
          <span class="menu-title">Roles and Permissions</span>
        </a>
      </li> -->
    </ul>
  </nav>
  <!-- - -->
  <div [ngClass]="isShow ? 'show-right-section' : 'hide-right-section' " class="side-section">

    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- - -->
</div>